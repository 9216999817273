import {lazy} from 'react';
import i18n from '../../i18n';

import {
    BoxPlotOutlined,
    UnorderedListOutlined,
    FontColorsOutlined
} from '@ant-design/icons'

const options = [
    {
        key: Math.random(),
        path: '/AppVariables',
        component: lazy(() => import('../appVariables/List')),
        exact: true,
        name: i18n.t('App Variables'),
        icon: FontColorsOutlined,
        render: true
    },
    {
        key: Math.random(),
        path: '/Categories',
        component: lazy(() => import('../category/List')),
        exact: true,
        name: i18n.t('Categories'),
        icon: UnorderedListOutlined,
        render: true
    },
    {
        key: Math.random(),
        path: '/Items',
        component: lazy(() => import('../item/List')),
        exact: true,
        name: i18n.t('Items'),
        icon: BoxPlotOutlined,
        render: true
    }
];
export default options;
